/* General Reset */
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: monospace;
	font-weight: 100;
	color: #000;
  }
  
  body {
	margin: 0;
  }
  
  /* Typography */
  h1, h2, h3, p {
	margin-top: 0;
	text-align: center;
  }
  
  h1 {
	font-size: 2rem;
  }
  
  h2 {
	font-size: 1.5rem;
	color: inherit;
  }
  
  h3 {
	font-size: 1rem;
  }
  
  p {
	line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6, p {
	margin-bottom: 1em;
  }
  
  /* Section Styles with Centering */
  #start,
  #one,
  #two,
  #three,
  #four,
  #five,
  #six,
  #seven,
  .project {
	display: flex;
	flex-direction: column; /* Align items vertically */
	justify-content: center; /* Center vertically */
	align-items: center; /* Center horizontally */
	text-align: center; /* Center text */
	height: 100vh; /* Full viewport height */
	padding: 5em;
	width: 100vw;
  }
  
  /* Sticky Content */
  .sticky {
	position:sticky;
	top:1em;
	width: 80%; /* Optional: Adjust as needed for content width */
	max-width: 800px; /* Optional: Set a max-width for better readability */
	margin: auto; /* Center inside the container */
  }
  
  /* Section Background Colors */
  #start {
	background: #E0FFFF; /* Soft Neon Cyan */
  }
  
  #one {
	background: #E6FFEB; /* Light Mint Green */
  }
  
  #two {
	background: #FFFFE0; /* Pastel Yellow */
  }
  
  #three {
	background: #F3E5FF; /* Soft Neon Lavender */
  }
  
  #four {
	background: #FFE6F7; /* Blush Pink */
  }
  
  #five {
	background: #E6F7FF; /* Cool Sky Blue */
  }
  
  #six {
	background: #FFEAE0; /* Pastel Coral */
  }
  
  #seven {
	background: #FFF5E6; /* Light Neon Peach */
  }
  
  /* Project Section */
  .project {
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5em;
	text-align: center;
  }
  
  .projectIMG {
	width:100%;
	max-width: 500px;
	margin: 2em auto;
	display: block;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 0; /* Flat styling */
  }
  
  /* Buttons */
  button {
	padding: 0.75em 1.5em;
	font-size: 1rem;
	background-color: #FF1493; /* Accent: Hot Pink */
	color: #fff;
	border: none;
	text-transform: uppercase;
	cursor: pointer;
	box-shadow: -3px 3px 0px #1E1E1E;
	transition: all 0.3s ease;
	margin: 1em auto;
  }
  
  button:hover {
	background-color: #FF4500; /* Bright Orange */
	color: #fff;
  }
  
  /* Links */
  a {
	text-decoration: none;
	color: inherit;
  }
  
  a:hover {
	color: #00FFFF; /* Electric Cyan */
	text-decoration: underline;
  }

  iframe {
	height: max-content;
    width: 100%;
  }
  
  /* Contact Form */
  form {
	display: flex;
	flex-direction: column;
	gap: 1em;
  }
  
  form input,
  form textarea {
	width: 100%;
	padding: 0.75em;
	font-size: 1rem;
	border: 1px solid #000;
	border-radius: 0; /* Flat styling */
	outline: none;
  }
  
  form textarea {
	resize: vertical;
  }
  
  form button {
	align-self: flex-start;
  }
  
  /* Footer */
  footer {
	background-color: #1E1E1E;
	color: #fff;
	text-align: center;
	padding: 2em;
  }

  address, footer > p {
	color:#fff
  }
  
  footer a {
	color: #FFFF33; /* Electric Yellow */
  }
  
  footer a:hover {
	color: #FF1493; /* Hot Pink */
  }
  
  /* Shadows and Accents */
  img {
	border-radius: 0; /* Flat styling */
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Media Queries */
  @media (max-width: 900px) {
	.gridShowcase {
	  grid-template-columns: repeat(2, 1fr);
	}
  }
  
  @media (max-width: 600px) {
	.gridShowcase {
	  grid-template-columns: 1fr;
	}
  
	.gridHeader {
	  gap: 0.5rem;
	}
  
	.grid > h2,
	h2 {
	  margin-top: 3em;
	}
  }
  
  @media screen and (max-width: 400px) {
	img.icon {
	  display: none;
	}
  }
  